.login-container {
	position: relative;
	z-index: 1000;
	display: flex;
	justify-content: center;
	height: 75vh;
	width: 65vh;
	border-radius: 10px;
	background-color: #f0f4f3;
	overflow: scroll;
	scrollbar-width: none;
}

.card-login {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.login-card {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	margin-bottom: 10px;
	width: 100%;
	height: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	align-items: center;
}

.login-card-t {
	margin-top: 5%;
}

.login-card-t h2 {
	font-size: 24px;
	color: #333;
	margin-bottom: 0.5rem;
}

.login-card-t p {
	font-size: 18px;
	color: #666;
	margin-bottom: 1.5rem;
}

.cpf-input-login{
  font-size: 26px!important;
}