.Bbutton {
  width: 90%;
	max-width: 90%;
	padding: 12px;
	background: linear-gradient(90deg, rgb(108, 180, 164), rgba(46, 149, 125));
	color: white;
	font-size: 16px;
	border: none;
	border-radius: 22px;
	cursor: pointer;
}
