.cadastro-input {
	width: 95%;
	padding: 10px;
	margin-bottom: 15px;
	border: none;
	border-bottom: 2px solid #4caf50;
	outline: none;
	font-size: 16px !important;
	color: #333;
	text-align: center;
	background-color: transparent;
}
