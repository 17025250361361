
.empresas-card-t {
	margin-top: 0;
}

.empresas-card h2 {
	font-size: 24px;
	font-weight: bold;
	color: #333;
	margin-top: 0;
}

.empresa-list {
	display: flex;
	flex-direction: column;
}

.empresa-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f8f8f8;
	border: 1px solid #ddd;
	color: black;
	border-radius: 8px;
}

.votar-button:disabled,
.voted-button:disabled {
	cursor: not-allowed;
}

.back-arrow-container {
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 1000;
}

.back-arrow {
	background: none;
	border: none;
	cursor: pointer;
	font-size: 20px;
	padding: 5px;
	color: #333;
}

.empresas-button-container .voted-button {
	background-color: white;
	color: black;
	cursor: not-allowed;
}

@media (max-width: 768px) {
	.perfil-card {
		width: 100%;
	}

	.categorias-container {
		grid-template-columns: 1fr;
	}

	.search-input {
		width: 100%;
	}
	.back-arrow {
		font-size: 18px;
	}

	.back-arrow-container {
		top: 5px;
		left: 5px;
	}
}

@media (max-width: 480px) {
	.back-arrow {
		font-size: 16px;
	}

	.back-arrow-container {
		top: 5px;
		left: 5px;
	}
}
