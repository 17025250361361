.v-button {
	width: 100%;
	color: white;
	background-color: rgba(46,149,125,1);
	padding: 7px;
	border: none;
	border-radius: 20px;
	cursor: pointer;
	transition: background-color 0.3s ease, color 0.3s ease;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 5px;
}
.v-button.voted {
	background: white;
	color: black;
	border: 1px solid black;
	width: 120px;
	padding: 7px;
	border: none;
	border-radius: 20px;
	cursor: pointer;
	transition: background-color 0.3s ease, color 0.3s ease;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 5px;
}
