.home-container {
	height: 100vh;
	background-image: url('../../assets/fundo.png');
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	text-align: center;
}

.img-logo {
	width: 25vw;
	min-width: 200px;
}

.home-button {
  width: 100%;
	max-width: 350px;
	padding: 12px;
	color: white;
	font-size: 16px;
	border: none;
	border-radius: 22px;
	cursor: pointer;
}

.home-button a {
	text-decoration: none;
	color: white;
}
