.acesso-card-t h2 {
	font-size: 25px;
	color: #333;
	margin-bottom: 5%;
}

.acesso-card-t p {
	font-size: 14px;
	color: black;
	margin-bottom: 10%;
	margin-left: 10%;
	margin-right: 10%;
}

.codigo-input {
	width: 80%;
	padding: 10px;
	margin-bottom: 10%;
	border: none;
	border-bottom: 2px solid #4caf50;
	outline: none;
	font-size: 16px;
	color: #333;
	text-align: center;
	background-color: transparent;
}

.codigo-acesso {
	font-size: 24px !important;
}

.message {
	margin-top: -3px;
	font-size: 14px;
	color: red;
}

.acesso-button:hover {
	background-color: #28a745;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
  appearance: textfield;
}
