.Small-button {
	min-width: 50%;
	padding: 5px 10px;
	background-color: transparent;
	color: black;
	border: 2px solid black;
	border-radius: 22px;
	font-size: 14px;
	cursor: pointer;
	margin-top: 10px;
	margin-bottom: 1px;
}
