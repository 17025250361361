body,
html {
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
	background-color: #f0f4f3;
	height: 100%;
	overflow-y: hidden;
}

body {
	height: 100%;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.home-container {
	height: 100vh;
	background-image: url('./assets/fundo.png');
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	text-align: center;
}

.home-button {
	padding: 10px 70px;
	font-size: 16px;
	color: white;
	cursor: pointer;
	transition: background-color 0.3s;
	background-color: rgba(44, 148, 124, 1);
	border-radius: 30px;
}

.cpf-input {
	width: 95%;
	padding: 10px;
	margin-bottom: 50px;
	border: none;
	border-bottom: 3px solid #4caf50;
	outline: none;
	font-size: 15px;
	color: #333;
	text-align: center;
	background-color: transparent;
}

.container {
	height: 100vh;
}

.card-principal {
	margin-top: 10vh;
	max-height: 80vh;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
}

.card-body {
	flex-grow: 1;
	overflow-y: auto;
	overflow-x: hidden;
}

html,
body {
	height: 100%;
}
.back-arrow-btn {
	width: 30px;
	height: auto;
}

.back-arrow-btn {
	background: none;
	border: none;
	cursor: pointer;
	width: 50px !important;
	height: auto;
	padding: 5px;
	color: #333;
}

.img-logo-destaque {
	width: 260px !important;
	max-width: 260px !important;
	margin: 10px 0;
}

.linear-bg-color {
	background: linear-gradient(90deg, rgb(108, 180, 164), rgba(46, 149, 125));
}

.search-container {
	position: relative;
	display: flex;
	justify-content: center;
	margin-bottom: 5px;
	margin-top: 10px;
}

.search-input {
	min-width: 80%;
	max-width: 100%;
	padding: 12px 20px;
	border: none;
	border-bottom: 2px solid #4caf50;
	font-size: 16px;
	outline: none;
}

.search-input:focus {
	border-color: #28a745;
}

.search-icon {
	position: absolute;
	right: 12%;
	top: 50%;
	transform: translateY(-50%);
	font-size: 18px;
	color: #777;
}

* {
	scrollbar-width: auto;
	scrollbar-color: rgba(46, 149, 125) #ffffff;
}

*::-webkit-scrollbar {
	width: 10px;
}

*::-webkit-scrollbar-track {
	background: #ffffff;
}

*::-webkit-scrollbar-thumb {
	background-color: rgba(46, 149, 125);
	border-radius: 10px;
	border: 3px solid #0d0d0d;
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(66, 66, 66, 0.8);
	z-index: 99999;
	display: none;
}

.backdrop img,
.dots {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.backdrop img {
	width: 250px;
	height: auto;
	transform: translate(-50%, -100%);
}
.dots {
	display: flex;
	gap: 2rem;
	transform: translate(-50%, 0);
}

.dot {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: rgba(40, 21, 113, 1);
	opacity: 1;
}

.dots .dot:nth-last-child(1) {
	animation: loadingC 0.6s 0.1s linear infinite;
}

.dots .dot:nth-last-child(2) {
	animation: loadingC 0.6s 0.2s linear infinite;
}

.dots .dot:nth-last-child(3) {
	animation: loadingC 0.6s 0.3s linear infinite;
}

@keyframes loadingC {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(0, 15px);
	}
	100% {
		transform: translate(0, 0);
	}
}

.over-hidden {
	overflow: hidden;
}
